.container {
	width: 100%;
	max-width: 1300px; /* Adjust for larger screens */
	margin: 0 auto;
	padding: 0;
}
.logoImage {
	width: 196px;
	height: 37px;
}
.inputForm-contactus {
	padding: 20px 20px 17px 20px;
	background-color: white;
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.32px;
	border-radius: 4px;
	border: 2px solid var(--Brand-Secondary, #b6dbfe);
	background: #fff;
	backdrop-filter: blur(2.9000000953674316px);
}
.textForm-contactus{
  height: 175px;
}

/* 4K screens (≥1920px) */
@media (min-width: 1920px) {
	.container {
		max-width: 1800px;
	}
}
/* Ultra-wide screens (≥1600px) */
@media (min-width: 1600px) {
	.container {
		max-width: 1600px;
	}
}

@media (max-width: 1300px) {
	.container {
		max-width: 90%;
	}
}
/* Medium screens (≤1024px) */
@media (max-width: 1024px) {
	.container {
		max-width: 90%;
	}
}

/* Tablet (≤768px) */
@media (max-width: 768px) {
	.container {
		max-width: 95%;
		padding: 0 12px;
	}
}

/* Mobile (≤480px) */
@media (max-width: 480px) {
	.container {
		max-width: 100%;
		padding: 0 8px;
	}
  .inputForm-contactus{
    width: 100% !important;
  }
}
