.simsola-loader {
    position: fixed; /* Ensures the loader overlays the entire viewport */
    top: 0;
    left: 0;
    width: 100%; /* Covers the full width of the screen */
    height: 100vh; /* Covers the full height of the screen */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 244, 246, 0.8); /* Semi-transparent background */
    z-index: 9999; /* Places the loader above all other elements */
}

.loader-text {
    font-size: 2rem;
    font-weight: bold;
    color: #133466;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    margin-bottom: 1rem;
    animation: fadeIn 1.5s infinite;
}

.loader-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.loader-dots span {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #133466;
    border-radius: 50%;
    animation: bounce 1.2s infinite ease-in-out;
}

.loader-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loader-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes fadeIn {
    0%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
